import React ,{ Component }from "react";
import { FiCast , FiLayers , FiUsers , FiMonitor } from "react-icons/fi";

const ServiceList = [
    {
        icon: <FiCast />,
        title: 'Webseitenerstellung',
        description: 'Wir erstellen maßgeschneiderte Webseiten, die perfekt auf die Bedürfnisse und Ziele Ihres Unternehmens abgestimmt sind. Egal ob Unternehmensseite, Online-Shop oder Blog – wir sorgen für einen professionellen Auftritt im Web.'
    },
    {
        icon: <FiLayers />,
        title: 'Webdesign',
        description: 'Ein ansprechendes und benutzerfreundliches Design ist der Schlüssel zum Erfolg Ihrer Webseite. Wir entwerfen moderne und intuitive Layouts, die \n' +
            'Ihre Besucher begeistern und zum Verweilen einladen.'
    },
    {
        icon: <FiUsers />,
        title: 'Webentwicklung',
        description: 'Von einfachen Webseiten bis hin zu komplexen Webanwendungen – wir setzen Ihre Ideen mit den neuesten Technologien und bewährten Methoden in die Tat um. Sauberer Code und optimale Performance sind dabei garantiert.'
    },
    {
        icon: <FiMonitor />,
        title: 'Hosting',
        description: 'Wir bieten zuverlässige und sichere Hosting-Lösungen, die auf die Anforderungen Ihrer Webseite zugeschnitten sind. Mit unserem Hosting-Service können Sie sich darauf verlassen, dass Ihre Webseite stets erreichbar und schnell ist.'
    },
    {
        icon: <FiUsers />,
        title: 'Wartung und Support',
        description: 'Unser Rundum-sorglos-Paket für Ihre Webseite: Wir kümmern uns um regelmäßige Updates, Sicherheit, Backups und Fehlerbehebungen. So bleibt Ihre Webseite stets aktuell und geschützt, während Sie sich voll und ganz auf Ihr Kerngeschäft konzentrieren können.'
    },
    {
        icon: <FiMonitor />,
        title: 'SEO',
        description: 'Wir optimieren Ihre Webseite für Suchmaschinen, damit Sie besser gefunden werden und mehr Besucher anziehen. Mit gezielten SEO-Maßnahmen verbessern wir Ihr Ranking und erhöhen die Sichtbarkeit Ihrer Seite im Internet.'
    }
]


class ServiceThree extends Component{
    render(){
        const {column } = this.props;
        const ServiceContent = ServiceList.slice(0 , this.props.item);

        return(
            <React.Fragment>
                <div className="row">
                    {ServiceContent.map( (val , i) => (
                        <div className={`${column}`} key={i}>
                            <a>
                                <div className="service service__style--2">
                                    <div className="icon">
                                        {val.icon}
                                    </div>
                                    <div className="content">
                                        <h3 className="title">{val.title}</h3>
                                        <p>{val.description}</p>
                                    </div>
                                </div>
                            </a>
                        </div>
                    ))}
                </div>
            </React.Fragment>
        )
    }
}
export default ServiceThree;
