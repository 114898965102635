import React, {Component} from "react";
import {FiCast, FiLayers, FiMonitor, FiUsers} from "react-icons/fi";
import RocketTextElement from "./RocketTextElement";

const steps = [
    {
        title: 'Webseitenerstellung',
        description: 'Wir erstellen maßgeschneiderte Webseiten, die perfekt auf die Bedürfnisse und Ziele Ihres Unternehmens abgestimmt sind. Egal ob Unternehmensseite, Online-Shop oder Blog – wir sorgen für einen professionellen Auftritt im Web.'
    },
    {
        title: 'Webdesign',
        description: 'Ein ansprechendes und benutzerfreundliches Design ist der Schlüssel zum Erfolg Ihrer Webseite. Wir entwerfen moderne und intuitive Layouts, die \n' +
            'Ihre Besucher begeistern und zum Verweilen einladen.'
    },
    {
        title: 'Webentwicklung',
        description: 'Von einfachen Webseiten bis hin zu komplexen Webanwendungen – wir setzen Ihre Ideen mit den neuesten Technologien und bewährten Methoden in die Tat um. Sauberer Code und optimale Performance sind dabei garantiert.'
    },
    {
        title: 'Hosting',
        description: 'Wir bieten zuverlässige und sichere Hosting-Lösungen, die auf die Anforderungen Ihrer Webseite zugeschnitten sind. Mit unserem Hosting-Service können Sie sich darauf verlassen, dass Ihre Webseite stets erreichbar und schnell ist.'
    },
    {
        title: 'Wartung und Support',
        description: 'Unser Rundum-sorglos-Paket für Ihre Webseite: Wir kümmern uns um regelmäßige Updates, Sicherheit, Backups und Fehlerbehebungen. So bleibt Ihre Webseite stets aktuell und geschützt, während Sie sich voll und ganz auf Ihr Kerngeschäft konzentrieren können.'
    },
    {
        title: 'SEO',
        description: 'Wir optimieren Ihre Webseite für Suchmaschinen, damit Sie besser gefunden werden und mehr Besucher anziehen. Mit gezielten SEO-Maßnahmen verbessern wir Ihr Ranking und erhöhen die Sichtbarkeit Ihrer Seite im Internet.'
    }
]

class Rocket extends Component {
    render() {
        return (
            <div className="bg_color--5" id="rocket-area">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="about-inner inner">
                                <div className="section-title">
                                    <h2 className="title">So wird Ihre Vision Wirklicheit</h2>
                                </div>
                            </div>
                        </div>
                        <div className="flex-column">
                            {
                                steps.map((val , i) => (
                                    <RocketTextElement title={val.title} description={val.description} />
                                ))
                            }

                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Rocket;
