import React, { Component , Fragment } from "react";
import Scrollspy from 'react-scrollspy';
import ScrollToTop from 'react-scroll-up';
import Particles from "react-tsparticles";
import {FiChevronUp, FiX, FiMenu, FiHeadphones, FiMail} from "react-icons/fi";
import ServiceList from "../elements/service/ServiceList";
import BlogContent from "../elements/blog/BlogContent";
import BrandTwo from "../elements/BrandTwo";
import FooterTwo from "../component/footer/FooterTwo";
import Contact from "../elements/contact/ContactTwo";
import Helmet from "../component/common/Helmet";
import SectionTitle from "../elements/common/SectionTitle";
import Rocket from "../rocket/Rocket";

const SlideList = [
    {
        logoPath: '/assets/images/about/velury-logo-white.png',
        logoAlt: 'velury-logo-black.png',
        textPosition: 'text-center',
        description: 'Wir machen ihre Vision zur Wirklichkeit',
        buttonText: 'Kontakt',
        buttonLink: '/contact'
    }
]

class HomeParticles extends Component{
    constructor(props) {
        super(props);
        this.menuTrigger = this.menuTrigger.bind(this);
        this.CLoseMenuTrigger = this.CLoseMenuTrigger.bind(this);
        this.stickyHeader = this.stickyHeader.bind(this);

       //  this.subMetuTrigger = this.subMetuTrigger.bind(this);
        window.addEventListener('load', function() {
            console.log('All assets are loaded');
        })
        window.addEventListener('scroll', () => {
            document.body.style.setProperty('--scroll', window.pageYOffset / (document.body.offsetHeight - window.innerHeight));
        }, false);
        document.addEventListener("DOMContentLoaded", function () {
            const animatedElement = document.querySelector('.rocket');

            const observer = new IntersectionObserver((entries) => {
                entries.forEach(entry => {
                    if (entry.isIntersecting) {
                        animatedElement.classList.add('visible');
                        observer.unobserve(animatedElement); // Optional: Beobachtung beenden, wenn Animation nur einmal starten soll
                    }
                });
            });

            observer.observe(animatedElement);
        });
    }
    menuTrigger() {
        document.querySelector('.header-wrapper').classList.toggle('menu-open')
    }
    CLoseMenuTrigger() {
        document.querySelector('.header-wrapper').classList.remove('menu-open')
    }
    stickyHeader () {}
    render(){
        const PostList = BlogContent.slice(0 , 5);

        window.addEventListener('scroll', function() {
            var value = window.scrollY;
            if (value > 100) {
                document.querySelector('.header--fixed').classList.add('sticky')
            }else{
                document.querySelector('.header--fixed').classList.remove('sticky')
            }
        });

        var elements = document.querySelectorAll('.has-droupdown > a');
        for(var i in elements) {
            if(elements.hasOwnProperty(i)) {
                elements[i].onclick = function() {
                    this.parentElement.querySelector('.submenu').classList.toggle("active");
                    this.classList.toggle("open");
                }
            }
        }

        return(
            <Fragment>
                <Helmet pageTitle="Home Particles"/>

                {/* Start Header Area  */}
                <header className="header-area formobile-menu header--fixed default-color">
                    <div className="header-wrapper" id="header-wrapper">
                        <div className="header-left">
                            <div className="logo">
                                <a href="/">
                                    <img className="logo-1" src="/assets/images/about/velury-icon-white.png"
                                         alt="Logo Images"/>
                                    <img className="logo-2" src="/assets/images/about/velury-icon-black.png"
                                         alt="Logo Images"/>
                                </a>
                            </div>
                        </div>
                        <div className="header-right">
                            <nav className="mainmenunav d-lg-block">
                                <Scrollspy className="mainmenu" items={['home', 'service', 'about', 'contact']}
                                           currentClassName="is-current" offset={-200}>
                                    <li><a href="#home">Home</a></li>
                                    <li><a href="#service">Dienstleistungen</a></li>
                                    <li><a href="#about">Über Uns</a></li>
                                    <li><a href="#contact">Kontakt</a></li>
                                </Scrollspy>
                            </nav>
                            {/* Start Humberger Menu  */}
                            <div className="humberger-menu d-block d-lg-none pl--20">
                                <span onClick={this.menuTrigger} className="menutrigger text-white"><FiMenu/></span>
                            </div>
                            {/* End Humberger Menu  */}
                            <div className="close-menu d-block d-lg-none">
                                <span onClick={this.CLoseMenuTrigger} className="closeTrigger"><FiX/></span>
                            </div>
                        </div>
                    </div>
                </header>
                {/* End Header Area  */}

                {/* Start Slider Area   */}

                <div className="slider-activation slider-creative-agency with-particles" id="home">
                    <div className="frame-layout__particles">
                        <Particles className="particle"
                                   options={{
                                       style: {
                                           position: "absolute"
                                       },
                                       fpsLimit: 100,
                                       interactivity: {
                                           detectsOn: "canvas",
                                           events: {
                                               onClick: {
                                                   enable: true,
                                                   mode: "push",
                                               },
                                               onHover: {
                                                   enable: true,
                                                   mode: "repulse",
                                               },
                                               resize: true,
                                           },
                                           modes: {
                                               bubble: {
                                                   distance: 100,
                                                   duration: 2,
                                                   opacity: 0.8,
                                                   size: 10,
                                                   color: "#888",
                                               },
                                               push: {
                                                   quantity: 4,
                                               },
                                               repulse: {
                                                   distance: 100,
                                                   duration: 0.4,
                                                   color: "#888",
                                               },
                                           },
                                       },
                                       particles: {
                                           color: {
                                               value: "#888",
                                           },
                                           links: {
                                               color: "#888",
                                               distance: 150,
                                               enable: true,
                                               opacity: 0.5,
                                               width: 1,
                                           },
                                           collisions: {
                                               enable: true,
                                           },
                                           move: {
                                               direction: "none",
                                               enable: true,
                                               outMode: "bounce",
                                               random: false,
                                               speed: 3,
                                               straight: true,
                                           },
                                           number: {
                                               density: {
                                                   enable: true,
                                                   value_area: 2000,
                                               },
                                               value: 80,
                                           },
                                           opacity: {
                                               value: 0.5,
                                           },
                                           shape: {
                                               type: "circle",
                                           },
                                           size: {
                                               random: true,
                                               value: 5,
                                           },
                                       },
                                       detectRetina: true,
                                   }}
                        />
                    </div>
                    <div className="bg_image bg_image--1">
                        {SlideList.map((value, index) => (
                            <div
                                className="slide slide-style-2 slider-paralax d-flex align-items-center justify-content-center"
                                key={index}>
                                <div className="container">
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className={`inner ${value.textPosition}`}>
                                                <img className="w-50" src={value.logoPath} alt={value.logoAlt}/>
                                                {value.description ?
                                                    <p className="description">{value.description}</p> : ''}
                                                {value.buttonText ? <div className="slide-btn"><a
                                                    className="rn-button-style--2 btn-primary-color"
                                                    href="#contact">{value.buttonText}</a></div> : ''}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
                {/* End Slider Area   */}

                {/* Start Service Area  */}
                <div className="service-area creative-service-wrapper ptb--120 bg_color--5" id="service">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6 col-12">
                                <div className="section-title text-left mb--30">
                                    <h2>Dienstleistungen</h2>
                                </div>
                            </div>
                        </div>
                        <div className="row creative-service">
                            <div className="col-lg-12">
                                <ServiceList item="6" column="col-lg-4 col-md-6 col-sm-6 col-12 text-left"/>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Service Area  */}

                {/* Start About Area */}
                <div className="about-area ptb--120 bg_color--1" id="about">
                    <div className="about-wrapper">
                        <div className="container">
                            <div className="row">
                            <div className="col-lg-7">
                                    <div className="about-inner inner">
                                        <div className="section-title">
                                            <h2 className="title">Über Uns</h2>
                                        </div>
                                    </div>
                                </div>
                                <p className="single-column">
                                    Willkommen bei Velury, Ihrem zuverlässigen Partner für erstklassige Webentwicklung
                                    und -design. Mit über fünf Jahren Berufserfahrung in der Branche sind wir darauf
                                    spezialisiert, maßgeschneiderte Lösungen zu bieten, die Ihre Online-Präsenz auf das
                                    nächste Level heben. <br/><br/>
                                    Unser Team besteht aus leidenschaftlichen und erfahrenen Webentwicklern, Designern
                                    und SEO-Experten, die gemeinsam daran arbeiten, Ihre Visionen zu verwirklichen. Wir
                                    verstehen die Bedeutung einer professionellen und funktionalen Webseite für den
                                    Erfolg Ihres Unternehmens. Daher setzen wir auf modernste Technologien und
                                    innovative Designs, um Ihnen eine Webseite zu bieten, die nicht nur optisch
                                    beeindruckt, sondern auch technisch überzeugt.<br/><br/>
                                    Bei uns steht die Kundenzufriedenheit an erster Stelle. Wir nehmen uns die Zeit,
                                    Ihre individuellen Bedürfnisse und Ziele zu verstehen und entwickeln darauf
                                    basierend maßgeschneiderte Lösungen. Von der ersten Konzeptidee bis zur finalen
                                    Umsetzung und darüber hinaus – wir begleiten Sie bei jedem Schritt des Projekts und
                                    stellen sicher, dass Ihre Webseite stets optimal funktioniert.<br/><br/>
                                    Unsere Dienstleistungen umfassen die komplette Webseitenerstellung, ansprechendes
                                    Webdesign, zuverlässige Webentwicklung, sicheres Hosting, kontinuierliche Wartung
                                    und umfassende Suchmaschinenoptimierung (SEO). Mit unserem Rundum-sorglos-Paket
                                    können Sie sich darauf verlassen, dass Ihre Webseite immer aktuell, sicher und
                                    leistungsstark bleibt.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End About Area */}

                {/* Start Contact Us */}
                <div className="rn-contact-us ptb--120 bg_color--5" id="contact">
                    <Contact/>
                    <div className="rn-contact-top-area ptb--120 bg_color--5">
                        <div className="container">

                            <div className="row justify-content-center">
                                {/* Start Single Address  */}
                                <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                                    <div className="rn-address">
                                        <div className="icon">
                                            <FiHeadphones/>
                                        </div>
                                        <div className="inner">
                                            <h4 className="title">Telefon</h4>
                                            <p><a href="tel:+49 159 01184567">+49 159 01184567</a></p>
                                            <p><a href="tel:+49 157 50107581">+49 157 50107581</a></p>
                                        </div>
                                    </div>
                                </div>
                                {/* End Single Address  */}

                                {/* Start Single Address  */}
                                <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt_mobile--50">
                                    <div className="rn-address">
                                        <div className="icon">
                                            <FiMail/>
                                        </div>
                                        <div className="inner">
                                            <h4 className="title">Email</h4>
                                            <p><a href="mailto:veluryhosting@gmail.com">veluryhosting@gmail.com</a></p>
                                            <br/>
                                        </div>
                                    </div>
                                </div>
                                {/* End Single Address  */}
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Contact Us */}

                {/* Start Brand Area */}
                <div className="rn-brand-area bg_color--1 ptb--30">
                    <div className="container">
                        <div className="row">
                            <div>
                                <title className="title color-2">Partner</title>
                            </div>
                            <div className="col-lg-12">
                                <BrandTwo/>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Brand Area */}

                {/* Start Footer Style  */}
                <FooterTwo/>
                {/* End Footer Style  */}

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp/>
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}

            </Fragment>
        )
    }
}

export default HomeParticles;
