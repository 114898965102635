import React, { Component } from "react";
import ContactForm from "./ContactForm";

class ContactTwo extends Component {
    render(){
        return(
            <div className="contact-form--1">
                <div className="container">
                    <div className="row row--35 align-items-start justify-content-center">
                        <div className="col-lg-6 order-2 order-lg-1">
                            <div className="section-title text-left mb--50">
                                <h2 className="title">Kontakt</h2>
                                <p className="description">Wir freuen uns darauf, von Ihnen zu hören! Egal, ob Sie eine Frage haben, ein Projekt besprechen möchten oder ein unverbindliches Angebot benötigen – wir sind für Sie da.</p>
                            </div>
                            <div className="form-wrapper">
                                <ContactForm />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default ContactTwo;
