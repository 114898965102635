import React, { Component } from "react";
import {Helmet} from 'react-helmet'

class PageHelmet extends Component{
    render(){
        return(
            <React.Fragment>
                <Helmet>
                    <title>Velury</title>
                    <meta name="description" content="Velury - Webdesigner und IT-Dienstleistungen. Wir bieten maßgeschneiderte Webseiten und IT-Dienstleistungen an."/>
                    <meta charSet="UTF-8"/>
                    <meta name="viewport" content="width=device-width, initial-scale=1.0"/>
                    <meta name="keywords" content="IT-Dienstleistungen, Webagentur, Webseiten, Webdesign, Webentwicklung"/>
                    <meta name="author" content="Velury Gbr"/>
                    <meta name="robots" content="index, follow"/>
                </Helmet>
            </React.Fragment>
        )
    }
}


export default PageHelmet;
