import React, { Component } from "react";
import Header from "../component/header/HeaderFour";
import Footer from "../component/footer/FooterTwo";

class impressum extends Component {
    render() {
        return (
            <>
                <Header headerPosition="header--transparent" color="color-black" logo="dark"/>
                <div className="impressum-page-inner">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="center">
                                    <h2 className="border-bottom">
                                        Impressum
                                    </h2>
                                </div><br/>
                                <div className="center">
                                    <p>
                                        Velury GbR<br/>
                                        Reginostraße 9<br/>
                                        67122 Altrip<br/><br/>
                                        Vertreten durch:<br/>
                                        Luca Korte<br/>
                                        Iven Krüger<br/>
                                    </p><br/>
                                    <h3>
                                        Kontakt
                                    </h3>
                                    <p>Telefon: 0159-01184567<br/>
                                        E-Mail: veluryhosting@gmail.com<br/>
                                    </p><br/>
                                    <h3>
                                        EU-Streitschlichtung<br/>
                                    </h3>
                                    <p>
                                        Die Europäische Kommission stellt eine Plattform zur Online-Streitbeilegung
                                        (OS)<br/>
                                        bereit:<br/><br/>
                                        <a href="https://ec.europa.eu/consumers/odr/" target="_blank"
                                           rel="noopener noreferrer">https://ec.europa.eu/consumers/odr/
                                        </a><br/><br/>
                                        Unsere E-Mail-Adresse finden Sie oben im Impressum.<br/><br/>
                                        <h3>
                                            Verbraucherstreitbeilegung/Universalschlichtungsstelle
                                        </h3><br/>
                                        Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren vor
                                        einer<br/>
                                        Verbraucherschlichtungsstelle teilzunehmen.<br/>
                                    </p>
                                    <br/><br/>
                                    <p>Quelle: <a href="https://www.e-recht24.de">e-recht24.de</a></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer/>
            </>
        )
    }
}

export default impressum;
